import Header from "./gHeader";
import Footr from "./gFooter";

function Layout({hvnId, children}){
    return(
        <>
            <Header />
            <main>
                {children}
            </main>
            <Footr hvnId={hvnId} />
        </>
    );

}
export default Layout;